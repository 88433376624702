/* SPDX-FileCopyrightText: 2014-present Kriasoft <hello@kriasoft.com> */
/* SPDX-License-Identifier: MIT */

import { CssBaseline, PaletteMode, Toolbar } from "@material-ui/core";
import { Box, Paper, Container } from "@mui/material";
import { ThemeProvider } from "@material-ui/core/styles";
import Link from '@mui/material/Link';
import { Action, Update } from "history";
import * as React from "react";
import { Environment, RelayEnvironmentProvider } from "react-relay";
import { History, HistoryContext, LocationContext } from "../core/history";
import type { RouteResponse } from "../core/router";
import { resolveRoute } from "../core/router";
import { LoginDialog } from "../dialogs";
import theme from "../theme";
import { AppToolbar } from "./AppToolbar";
import { ErrorPage } from "./ErrorPage";
import { Booking } from "./Booking";
import reception from '../assets/reception2.jpg';
import axios from "axios";
import { differenceInCalendarDays, addMonths, endOfMonth } from 'date-fns';
import { useNavigate } from "../hooks";
import logo from '../assets/logo.png';

type AppProps = {
  history: History;
  relay: Environment;
};

export class App extends React.Component<AppProps> {
  state = {
    route: undefined as RouteResponse | undefined,
    location: this.props.history.location,
    error: undefined as Error | undefined,
    theme: (window?.localStorage?.getItem("theme") === "dark"
      ? "dark"
      : "light") as PaletteMode,
    bookingOpen: false,
    sessions:[],
    sessionsTime:'',
    ax:null
  };

  static getDerivedStateFromError(error: Error): { error: Error } {
    return { error };
  }

  dispose?: () => void;

  componentDidMount(): void {
    const { history } = this.props;
    this.dispose = history.listen(this.renderPath);
    this.renderPath({ location: history.location, action: Action.Pop });
    const ax = axios.create({baseURL: 'https://data.physiosolutions.workers.dev/'});
    const date = new Date();
    const sSize = differenceInCalendarDays(endOfMonth(addMonths(date, 1)), date)+1;
    const sessions = [];
    this.setState({sessions:sessions, ax:ax});
  }

  componentDidUpdate(): void {
    if (this.state.route?.title) {
      self.document.title = this.state.route.title;
    }
  }

  componentWillUnmount(): void {
    if (this.dispose) this.dispose();
  }

  componentDidCatch(error: Error, errorInfo: unknown): void {
    // You can also log the error to an error reporting service
    console.error(error, errorInfo);
  }

  renderPath = async (ctx: Update): Promise<void> => {
    console.log('ctxUpdate', ctx);
    resolveRoute({
      path: ctx.location.pathname,
      search: ctx.location.search,
      relay: this.props.relay,
    }).then((route) => {
      if (route.error) console.error(route.error);
      this.setState({ route, location: ctx.location, error: route.error });
    });
  };

  handleChangeTheme = (): void => {
    this.setState((x: { theme: PaletteMode }) => {
      const theme = x.theme === "light" ? "dark" : "light";
      window.localStorage?.setItem("theme", theme);
      return { ...x, theme };
    });
  };

  handleBookingOpen = () => {
    this.setState({bookingOpen:true});
    this.state.ax.get('sessions4').then((response) => {
      console.log('sessions', response.data);
      this.setState({sessions:response.data.sessions,
        sessionsTime:response.data.time});
    });
  };

  handleBookingClose = () => {
    this.setState({bookingOpen:false});
  };

  render(): JSX.Element {
    const { history } = this.props;
    const { route, location, error } = this.state;

    if (error) {
      return (
        <ThemeProvider theme={theme[this.state.theme]}>
          <ErrorPage error={error} history={history} />;
        </ThemeProvider>
      );
    }

    return (
      <ThemeProvider theme={theme[this.state.theme]}>
        <RelayEnvironmentProvider environment={this.props.relay}>
          <HistoryContext.Provider value={history}>
            <LocationContext.Provider value={location}>
              <CssBaseline />
              <LogoBox />
              <Box>
                <AppToolbar onChangeTheme={this.handleChangeTheme}
                  handleBookingOpen={this.handleBookingOpen} />
                {/* <Toolbar /> */}
                {route?.component
                  ? React.createElement(route.component, {...route.props,
                    handleBookingOpen:this.handleBookingOpen})
                  : null}
              </Box>
              {/* <LoginDialog /> */}
              <Booking open={this.state.bookingOpen}
                handleOpen={this.handleBookingOpen}
                handleClose={this.handleBookingClose}
                sessions={this.state.sessions}
                sessionsTime={this.state.sessionsTime}
                ax={this.state.ax}
              />
            </LocationContext.Provider>
          </HistoryContext.Provider>
        </RelayEnvironmentProvider>
      </ThemeProvider>
    );
  }
}

function LogoBox(props) {
  const navigate = useNavigate();
  return (
    <Box sx={{my:5, display: 'flex', justifyContent: 'center'}}>
      <Link color="#004000" underline="none" href="/" onClick={navigate}>
        <img src={logo} width={250} />
      </Link>
    </Box>
  )
}
