import CloseIcon from '@mui/icons-material/Close';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import PickersDay from '@mui/lab/PickersDay';
import StaticDatePicker from '@mui/lab/StaticDatePicker';
// import LocalizationProvider from '@mui/x-date-pickers/LocalizationProvider';
// import PickersDay from '@mui/x-date-pickers/PickersDay';
// import StaticDatePicker from '@mui/x-date-pickers/StaticDatePicker';
import { CardActionArea } from '@mui/material';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Stepper from '@mui/material/Stepper';
import { styled, useTheme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import useMediaQuery from '@mui/material/useMediaQuery';
import { addMonths, differenceInCalendarDays, endOfMonth, addDays, addHours } from 'date-fns';
import * as React from 'react';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Alert from '@mui/material/Alert';
import axios from "axios";

export class Booking extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        selectedSession:"",
        info:{},
        times: new Array(23).fill(''),
        error:'',
        price:0,
        mvDelta: 0
      };
    }
    setSelectedSession = (session) => {
      this.setState({selectedSession:session});
    }
    setInfo = (info) => {
      this.setState({info:info});
    }
    book = (id, name, phone, email) => {
      this.props.ax.post('pay4',
        JSON.stringify({
        // day:this.state.selectedSession[0],
        session:this.state.selectedSession,
        booking:{id:id, name:name, phone:phone, email:email},
        time:this.props.sessionsTime
      })).then(r => {
        console.log(r);
        if (r.status === 200) {
          if (r.data.error) {
            this.setState({error:r.data.error})
          }
          else {window.location.replace(r.data.url)}
        }
      });
      // this.props.handleClose();
    }
    render() {
      return (
        <BookingDialog open={this.props.open} handleOpen={this.props.handleOpen}
          handleClose={this.props.handleClose} sessions={this.props.sessions}
          setSelectedSession={this.setSelectedSession} setInfo={this.setInfo}
          selectedSession={this.state.selectedSession} book={this.book}
          times={this.state.times} error={this.state.error}
          price={this.state.price} mvDelta={this.state.mvDelta} />
      );
    }
    componentDidMount() {
      const ax = axios.create({baseURL: 'https://data.physiosolutions.workers.dev/'});
      // ax.get('times').then(r => {
      //   // console.log(r);
      //   if (r.status === 200) {
      //     this.setState({times:r.data});
      //   }
      // });
      ax.get('values').then(r => {
        // console.log(r);
        if (r.status === 200) {
          const mvTime = new Date(r.data.mvTime);
          const localTime = new Date();
          let delta = localTime - mvTime;
          if (delta < 0) delta *= -1;
          this.setState({price:r.data.price_appointment, mvDelta:delta});
        }
      });
    }
  }

  function BookingDialog(props) {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [activeStep, setActiveStep] = React.useState(0);
    const handleClose = () => {
      props.handleClose();
      setActiveStep(0);
    }
    const getRows = () => {
      var rows = [];
      var dateNow = new Date();
      const days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thur', 'Fri', 'Sat'];
      props.sessions.forEach((day, i) => {
        var date = new Date(dateNow.getFullYear(), dateNow.getMonth(),
          dateNow.getDate()+i);
        var row = [`${date.getDate()} ${days[date.getDay()]}`];
        day.forEach((available, j) => {
          row.push(available);
        })
        rows.push(row);
      });
      // console.log('rows', rows);
      return rows;
    }
    const handleStepButton = (i) => {
      setActiveStep(i);
    }
    var steps = ["Pick a time", "Enter info", "Pay"];
    return (
      <div>
        <Dialog
          fullScreen={fullScreen}
          fullWidth
          open={props.open}
          onClose={handleClose}
        >
          <DialogTitle>
            <Stepper activeStep={activeStep} sx={{mr:3}}>
              {steps.map((step, i) => (
                <Step key={i}>
                  <StepButton onClick={() => handleStepButton(i)}>{step}</StepButton>
                </Step>
              ))}
            </Stepper>
            <IconButton
              onClick={handleClose}
              sx={{position: 'absolute', right: 8, top: 8}}
            >
              <CloseIcon />
            </IconButton>
          </DialogTitle>
          <DialogContent>
            <BookingBody getRows={getRows} times={props.times}
              activeStep={activeStep} setActiveStep={setActiveStep}
              setSelectedSession={props.setSelectedSession}
              setInfo={props.setInfo} selectedSession={props.selectedSession}
              book={props.book} sessions={props.sessions} error={props.error}
              price={props.price} mvDelta={props.mvDelta} />
          </DialogContent>
          {/* <DialogActions>
            <Button autoFocus onClick={props.handleClose}>
              Disagree
            </Button>
            <Button onClick={props.handleClose} autoFocus>
              Agree
            </Button>
          </DialogActions> */}
        </Dialog>
      </div>
    );
  }

  function BookingBody(props) {
    const [id, setId] = React.useState('');
    const [name, setName] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [email, setEmail] = React.useState('');
    const [selectedDay, setSelectedDay] = React.useState(-1);
    const [selectedDate, setSelectedDate] = React.useState(new Date());
    const [terms, setTerms] = React.useState(false);
    React.useEffect(() => {
      setSelectedDay(-1);
    }, []);
    const onSelectSession = (session) => {
      console.log("selectedSession", session);
      props.setSelectedSession(session);
      props.setActiveStep(1);
    }
    const onSelectDay = (day) => {
      // console.log('selected day', day);
      setSelectedDate(day);
      var diff = differenceInCalendarDays(day, new Date());
      // console.log('diff', diff);
      setSelectedDay(diff);
    }
    const onReview = () => {
      props.setActiveStep(2);
      props.setInfo({id:id, name:name, phone:phone, email:email});
    }
    const onPay = () => {
      props.book(id, name, phone, email);
      props.setActiveStep(3);
    }
    const onBack = () => {
      props.setActiveStep(props.activeStep-1);
    }
    const reviewButtonState = () => {
      if ((id.length > 4) && (name.length > 4) && (phone.length > 4)) return false;
      else return true;
    }
    const getChipStyles = () => {
      return {mr:1, mb:1};
    }
    const CustomPickersDay = styled(PickersDay, {
      shouldForwardProp: (prop) =>
        prop !== 'isUnavailable',
    })(({ theme, isUnavailable }) => ({
      ...(false && {
        borderRadius: 0,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.common.white,
        '&:hover, &:focus': {
          backgroundColor: theme.palette.primary.dark,
        },
      }),
      ...(isUnavailable && {
        backgroundColor: 'gray'
      }),
    }));
    const renderPickerDay = (date, selectedDates, pickersDayProps) => {

      return (
        <CustomPickersDay
          {...pickersDayProps}
          disableMargin
          isUnavailable={false}
        />
      );
    }
    const shouldDisableDate = (date) => {
      // let dateString = date.toISOString().split("T")[0];
      // if (dateString.localeCompare("2023-06-10") === 0) return false;
      var diff = differenceInCalendarDays(date, new Date());
      if ((diff < 0) || (diff >= 60)) return true;
      for (const s of props.sessions) {
        let dateS = new Date(s);
        // dateS = addHours(dateS, 5);
        dateS = addDays(dateS, -1);
        let dateSString = dateS.toISOString().split("T")[0];
        let dateString = date.toISOString().split("T")[0];
        // if (dateString.localeCompare("2023-06-03") === 0)
          // console.log(dateString, dateSString);
        if (dateString.localeCompare(dateSString) === 0) return false;
      }
      return true;
      // var availableCount = 0;
      // props.sessions[diff].map((s) => {if (s) availableCount++});
      // if (availableCount) return false;
      // else return true;
    }
    const getTimesRamadan = (date) => {
      if ((
          (date.getFullYear() == 2023) &&
          (date.getMonth() == 2) &&
          (date.getDate() >= 23)
        ) ||
        (
          (date.getFullYear() == 2023) &&
          (date.getMonth() == 3) &&
          (date.getDate() <= 27)
        )
      ) {
        let timesRamadan = props.times.slice(0,13);
        timesRamadan.push("09:00 PM", "09:40 PM", "10:20 PM", "");
        return timesRamadan;
      }
      else {
        return props.times;
      }
    }
    const getSessions = (day) => {
      const date = addDays(new Date(), day);
      const dateString = date.toISOString().split("T")[0];
      let morning = [];
      let afternoon = [];
      let night = [];
      for (const s of props.sessions) {
        let dateS = new Date(s);
        let dateSString = dateS.toISOString().split("T")[0];
        if (dateString === dateSString) {
          // console.log("dateS.getHours()", dateS.getHours());
          if ([6,7,8,9,10,11].includes(dateS.getHours()))
            morning.push(s);
          else if ([12,13,14,15,16,17].includes(dateS.getHours()))
            afternoon.push(s);
          else if ([0,1,2,3,4,5,18,19,20,21,22,23].includes(dateS.getHours()))
            night.push(s);
        }
      }
      return [morning, afternoon, night];
    }
    const notMVTimezone = () => {
      if (props.mvDelta > 30 * 60 * 1000) return true;
      else return false;
    }
    if (props.activeStep === 0) {
      // const rows = props.getRows();
      // console.log('rows', rows);
      const dayOfWeek = addDays(new Date(), selectedDay).getDay();
      // console.log('dayOfWeek', dayOfWeek);
      // return (
      //   <Typography variant="h4">
      //     Sorry, online booking is down for maintenance
      //   </Typography>
      // )
      if (notMVTimezone()) return (
        <Typography variant="h4">
          Sorry, we're not able to proceed in your timezone
        </Typography>
      )
      if (selectedDay < 0) return(
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            orientation="landscape"
            openTo="day"
            value={selectedDate}
            onChange={onSelectDay}
            renderDay={renderPickerDay}
            shouldDisableDate={shouldDisableDate}
            minDate={new Date()}
            maxDate={endOfMonth(addMonths(new Date(), 1))}
            renderInput={(params) => <TextField {...params} />}
          />
          {/* {rows.map((row, i) => {
          var availableCount = 0;
          row.slice(1).map((s) => {if (s) availableCount++});
          if (availableCount) return(
            <Chip key={i} label={row[0]} variant="outlined" sx={getChipStyles()}
              onClick={() => onSelectDay(i)} />
          )
        })} */}
        </LocalizationProvider>
      );
      const [morning, afternoon, night] = getSessions(selectedDay);
      return(
        <Grid container>
          <Grid item xs={4}>
            <Typography variant="h3">Morning</Typography>
              {morning.map((session, i) => {
                const date = new Date(session);
                return(
                  <Card key={i} sx={{mt:1, mr:2}}>
                    <CardActionArea onClick={() => onSelectSession(session)}>
                      <CardContent>
                        <Typography align="center" sx={{fontSize:14}}>
                          {date.toLocaleTimeString('en-US')}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                );
              })}
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h3">Afternoon</Typography>
              {afternoon.map((session, i) => {
                const date = new Date(session);
                return(
                  <Card key={i} sx={{mt:1, mr:2}}>
                    <CardActionArea onClick={() => onSelectSession(session)}>
                      <CardContent>
                        <Typography align="center" sx={{fontSize:14}}>
                          {date.toLocaleTimeString('en-US')}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                );
              })}
          </Grid>
          <Grid item xs={4}>
            <Typography variant="h3">Night</Typography>
              {night.map((session, i) => {
                const date = new Date(session);
                return(
                  <Card key={i} sx={{mt:1, mr:2}}>
                    <CardActionArea onClick={() => onSelectSession(session)}>
                      <CardContent>
                        <Typography align="center" sx={{fontSize:14}}>
                          {date.toLocaleTimeString('en-US')}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                );
              })}
          </Grid>
          <Grid item xs={12}>
            <Button sx={{mt:1}} fullWidth
              onClick={() => setSelectedDay(-1)}
            >
              Back
            </Button>
          </Grid>
        </Grid>
      );
      // else if (props.sessions[selectedDay].slice(17, 23).includes(true))
      //   return (
      //   <Grid container>
      //     <Grid item xs={4}>
      //       <Typography variant="h3">Afternoon</Typography>
      //       {props.sessions[selectedDay].slice(17, 23).map((session, i) => {
      //         const index = 17+i;
      //         if (session) {
      //           return(
      //             <Card key={index} sx={{mt:1, mr:2}}>
      //               <CardActionArea onClick={() => onSelectSession(index)}>
      //                 <CardContent>
      //                   <Typography align="center" sx={{fontSize:14}}>
      //                     {props.times[index]}
      //                   </Typography>
      //                 </CardContent>
      //               </CardActionArea>
      //             </Card>
      //           );
      //         }
      //       })}
      //     </Grid>
      //     <Grid item xs={12}>
      //       <Button sx={{mt:1}} fullWidth
      //         onClick={() => setSelectedDay(-1)}
      //       >
      //         Back
      //       </Button>
      //     </Grid>
      //   </Grid>
      // );
      // else return(
      //   <Grid container>
      //     <Grid item xs={4}>
      //       <Typography variant="h3">Morning</Typography>
      //         {props.sessions[selectedDay].slice(0, 6).map((session, i) => {
      //           const index = 0+i;
      //           console.log(index, session);
      //           let futureTime = true;
      //           if (selectedDay === 0)
      //             if (new Date().getHours() >= 12) futureTime = false;
      //           if (session && futureTime) {
      //             return(
      //               <Card key={index} sx={{mt:1, mr:2}}>
      //                 <CardActionArea onClick={() => onSelectSession(index)}>
      //                   <CardContent>
      //                     <Typography align="center" sx={{fontSize:14}}>
      //                       {props.times[index]}
      //                     </Typography>
      //                   </CardContent>
      //                 </CardActionArea>
      //               </Card>
      //             );
      //           }
      //         })}
      //     </Grid>
      //     <Grid item xs={4}>
      //       <Typography variant="h3">Afternoon</Typography>
      //       {props.sessions[selectedDay].slice(6, 13).map((session, i) => {
      //         const index = 6+i;
      //         let futureTime = true;
      //         if (selectedDay === 0)
      //           if (new Date().getHours() >= 18) futureTime = false;
      //         if (session && futureTime) {
      //           return(
      //             <Card key={index} sx={{mt:1, mr:2}}>
      //               <CardActionArea onClick={() => onSelectSession(index)}>
      //                 <CardContent>
      //                   <Typography align="center" sx={{fontSize:14}}>
      //                     {props.times[index]}
      //                   </Typography>
      //                 </CardContent>
      //               </CardActionArea>
      //             </Card>
      //           );
      //         }
      //       })}
      //     </Grid>
      //     <Grid item xs={4}>
      //       <Typography variant="h3">Night</Typography>
      //       {props.sessions[selectedDay].slice(13, 17).map((session, i) => {
      //         const index = 13+i;
      //         if (session) {
      //           return(
      //             <Card key={index} sx={{mt:1, mr:2}}>
      //               <CardActionArea onClick={() => onSelectSession(index)}>
      //                 <CardContent>
      //                   <Typography align="center" sx={{fontSize:14}}>
      //                     {getTimesRamadan(selectedDate)[index]}
      //                   </Typography>
      //                 </CardContent>
      //               </CardActionArea>
      //             </Card>
      //           );
      //         }
      //       })}
      //     </Grid>
      //     <Grid item xs={12}>
      //       <Button sx={{mt:1}} fullWidth
      //         onClick={() => setSelectedDay(-1)}
      //       >
      //         Back
      //       </Button>
      //     </Grid>
      //   </Grid>
      // );
    }
    else if (props.activeStep === 1) {
      return(
        <React.Fragment>
          <TextField id="name" label="Full name"
            variant="standard" value={name} fullWidth
            onChange={(e) => setName(e.target.value)}
            error={(name.length && name.length < 5)}>
          </TextField>
          <TextField id="nic" label="ID card or passport number"
            variant="standard" value={id} fullWidth
            onChange={(e) => setId(e.target.value)}
            error={(id.length && id.length < 5)}>
          </TextField>
          <TextField id="phone" label="Phone"
            variant="standard" value={phone} fullWidth
            onChange={(e) => setPhone(e.target.value)}
            error={(phone.length && phone.length < 5)}>
          </TextField>
          <TextField id="email" label="Email"
            variant="standard" value={email} fullWidth
            onChange={(e) => setEmail(e.target.value)} helperText="Optional">
          </TextField>
          <ButtonGroup variant="contained" sx={{marginTop:5}}>
            <Button color="primary" onClick={onReview} disabled={reviewButtonState()}>
              Review
            </Button>
            <Button onClick={onBack}>
              Back
            </Button>
          </ButtonGroup>
        </React.Fragment>
      );
    }
    else if (props.activeStep === 2) {
      const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday',
        'Friday', 'Saturday'];
      const dateNow = new Date();
      // const dateSession = new Date(dateNow.getFullYear(), dateNow.getMonth(),
      //   dateNow.getDate()+props.selectedSession[0]);
      const dateSession = new Date(props.selectedSession);
      return (
        <React.Fragment>
          <Card>
            <CardHeader title="Summary"
              titleTypographyProps={{variant:'h1', sx:{}}} />
            <CardContent>
              {/* <Typography variant="subtitle2">
                {`${dateSession.getDate()} ${days[dateSession.getDay()]} ${
                  props.times[props.selectedSession[1]]} 40 minutes`}
              </Typography> */}
              <Typography variant="subtitle2">
                {`${dateSession.toLocaleString()} 40 minutes`}
              </Typography>
              <Typography variant="subtitle2">{name}</Typography>
              <Typography variant="subtitle2">{id}</Typography>
              <Typography variant="subtitle2">{phone}</Typography>
              <Typography variant="subtitle2">{email}</Typography>
              <Typography variant="subtitle1"><br/>
                {`An initial payment of MVR ${props.price} as a booking fee for the appointment.`}
              </Typography>
              <FormGroup>
                <FormControlLabel control={<Checkbox value={terms} onChange={() => setTerms(!terms)} />} label={
                  <Typography>I agree to the <a href="/terms">terms of service</a> and <a href="/privacy">privacy policy</a></Typography>} />
              </FormGroup>
            </CardContent>
          </Card>
          <ButtonGroup variant="contained" sx={{mt:5}}>
            <Button onClick={onPay} disabled={!terms}>Pay</Button>
            <Button onClick={onBack}>Back</Button>
          </ButtonGroup>
        </React.Fragment>
      );
    }
    else if (props.activeStep === 3) {
      return (
        <React.Fragment>
          <Card>
            <CardHeader title="Redirecting"
              titleTypographyProps={{variant:'h1', sx:{}}} />
            <CardContent>
              <Typography variant="subtitle1">
                You are being redirected to BML payment gateway...
              </Typography>
              {(props.error?<Alert severity="error">{props.error}</Alert>:
                <div></div>)}
            </CardContent>
          </Card>
        </React.Fragment>
      );
    }
    else {
      return(<div></div>);
    }
  }
