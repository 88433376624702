/* SPDX-FileCopyrightText: 2014-present Kriasoft <hello@kriasoft.com> */
/* SPDX-License-Identifier: MIT */

import type { Route } from "../../core";
import type Paid from "./Paid";

export default
  {
    path: "/paid",
    component: () => import(/* webpackChunkName: "legal" */ "./Paid"),
    response: (data) => ({
      title: "Payment completed",
      props: data,
    }),
  } as Route<typeof Paid>;
