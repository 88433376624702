/* SPDX-FileCopyrightText: 2014-present Kriasoft <hello@kriasoft.com> */
/* SPDX-License-Identifier: MIT */

import type { Route } from "../../core";
import type Team from "./Team";

export default
  {
    path: "/team",
    component: () => import(/* webpackChunkName: "legal" */ "./Team"),
    response: () => ({
      title: "Team",
    }),
  } as Route<typeof Team>;
