/* SPDX-FileCopyrightText: 2014-present Kriasoft <hello@kriasoft.com> */
/* SPDX-License-Identifier: MIT */

// import {
//   AppBar,
//   AppBarProps,
//   Avatar,
//   Button,
//   Chip,
//   IconButton,
//   Link,
//   Toolbar,
//   Typography,
//   Paper,
// } from "@material-ui/core";
import { useTheme } from "@material-ui/core/styles";
import { ArrowDropDown, NotificationsNone } from "@material-ui/icons";
import * as React from "react";
import { config } from "../core";
import { useLoginDialog, useNavigate } from "../hooks";
import { NotificationsMenu, UserMenu } from "../menu";
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Slide from '@mui/material/Slide';
import logo from '../assets/logo.png';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import AppBarProps from '@mui/material/AppBarProps';
import Chip from '@mui/material/Chip';
import Link from '@mui/material/Link';

type AppToolbarProps = AppBarProps & {
  onChangeTheme: () => void,
  handleBookingOpen: () => void,
};

export function AppToolbar(props: AppToolbarProps): JSX.Element {
  const { sx, onChangeTheme, handleBookingOpen, ...other } = props;
  const menuAnchorRef = React.createRef<HTMLButtonElement>();
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = React.useState({
    userMenu: null as HTMLElement | null,
    notifications: null as HTMLElement | null,
  });

  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const navMenu = [
    // {name:'Home', href:"/"},
    {name:'Experience', href:"/experience"},
    // {name:'What we treat', href:"/treatments"},
    {name:'Services', href:"/services"},
    {name:'Team', href:"/team"},
    {name:'Education hub', href:"/education"},
    {name:'Gallery', href:"/gallery"},
    // {name:'Gallery', href:"/gallery"}
  ]

  const loginDialog = useLoginDialog();
  const navigate = useNavigate();
  // const user = useCurrentUser();

  function openNotificationsMenu() {
    setAnchorEl((x) => ({ ...x, notifications: menuAnchorRef.current }));
  }

  function closeNotificationsMenu() {
    setAnchorEl((x) => ({ ...x, notifications: null }));
  }

  function openUserMenu() {
    setAnchorEl((x) => ({ ...x, userMenu: menuAnchorRef.current }));
  }

  function closeUserMenu() {
    setAnchorEl((x) => ({ ...x, userMenu: null }));
  }

  // function signIn(event: React.MouseEvent): void {
  //   event.preventDefault();
  //   loginDialog.show();
  // }

  function HideOnScroll(props) {
    const { children, window } = props;
    // Note that you normally won't need to set the window ref as useScrollTrigger
    // will default to window.
    // This is only being set here because the demo is in an iframe.
    const trigger = useScrollTrigger({
      target: window ? window() : undefined,
    });
    return (
      <Slide appear={false} direction="down" in={!trigger}>
        {children}
      </Slide>
    );
  }

  return (
    // <HideOnScroll {...props}>
      <AppBar
        // sx={{ zIndex: (theme) => theme.zIndex.drawer + 1, ...sx }}
        // sx={{pt:0.5, mt:0}}
        color="inherit"
        elevation={0}
        position="static"
        {...other}
      >
        <Toolbar sx={{py:3, display:"flex", justifyContent:{xs: "start", md:"center"}}}>
          {/* App name / logo */}
          <Box sx={{display: { xs: 'flex', md: 'none' } }}>
            <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}
              variant="menu"
            >
              {navMenu.map((item, index) => (
                <MenuItem key={index}>
                  <Link href={item.href} onClick={navigate} variant="string"
                    color="primary.dark" underline="none"
                    sx={{fontSize:'1.0em', fontWeight:'medium'}}>
                      {item.name}
                  </Link>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          {/* <Box sx={{display: { xs: 'flex', md: 'flex' } }}>
            <Link color="#004000" underline="none" href="/" onClick={navigate}>
              <img src={logo} width={160} />
            </Link>
          </Box> */}
          <Box sx={{display: { xs: 'none', md: 'flex' }, justifyContent: 'center'}}>
            {navMenu.map((item, index) => (
              <Link key={index} href={item.href} onClick={navigate} variant="string"
                color="primary.dark" underline="none"
                sx={{mx: {xs:5, lg:7}, fontSize:'1.25em', fontWeight:'medium'}}>
                  {item.name}
              </Link>
            ))}
          </Box>
          {/* <span style={{ flexGrow: 1 }} /> */}
          <Button
              // href="/appointment"
              variant="contained"
              color="primary"
              size="large"
              children="BOOK"
              sx={{
                color: 'white'
              }}
              onClick={() => {handleBookingOpen()}}
            />

          {/* Account related controls (icon buttons) */}

          {/* {user && (
            <Chip
              sx={{
                height: 40,
                borderRadius: 20,
                fontWeight: 600,
                backgroundColor: (x) =>
                  x.palette.mode === "light"
                    ? x.palette.grey[300]
                    : x.palette.grey[700],
                ".MuiChip-avatar": { width: 32, height: 32 },
              }}
              component="a"
              avatar={
                <Avatar
                  alt={user.name || ""}
                  src={user?.picture?.url || undefined}
                />
              }
              label={getFirstName(user.name || "")}
              onClick={navigate}
            />
          )}
          {user && (
            <IconButton
              sx={{
                marginLeft: (x) => x.spacing(1),
                backgroundColor: (x) =>
                  x.palette.mode === "light"
                    ? x.palette.grey[300]
                    : x.palette.grey[700],
                width: 40,
                height: 40,
              }}
              children={<NotificationsNone />}
              onClick={openNotificationsMenu}
            />
          )}
          {user && (
            <IconButton
              ref={menuAnchorRef}
              sx={{
                marginLeft: (x) => x.spacing(1),
                backgroundColor: (x) =>
                  x.palette.mode === "light"
                    ? x.palette.grey[300]
                    : x.palette.grey[700],
                width: 40,
                height: 40,
              }}
              children={<ArrowDropDown />}
              onClick={openUserMenu}
            />
          )}
          {user && (
            <Button
              variant="outlined"
              href="/auth/google"
              color="primary"
              onClick={signIn}
              children="Log in / Register"
            />
          )} */}
        </Toolbar>

        {/* Pop-up menus */}

        <NotificationsMenu
          anchorEl={anchorEl.notifications}
          onClose={closeNotificationsMenu}
          PaperProps={{ sx: { marginTop: "8px" } }}
        />
        <UserMenu
          anchorEl={anchorEl.userMenu}
          onClose={closeUserMenu}
          PaperProps={{ sx: { marginTop: "8px" } }}
          onChangeTheme={onChangeTheme}
        />
      </AppBar>
    // </HideOnScroll>
  );
}

function getFirstName(displayName: string): string {
  return displayName && displayName.split(" ")[0];
}
