/* SPDX-FileCopyrightText: 2014-present Kriasoft <hello@kriasoft.com> */
/* SPDX-License-Identifier: MIT */

import type { Match, MatchFunction } from "path-to-regexp";
import { match as createMatchFn } from "path-to-regexp";
// import { fetchQuery } from "react-relay";
import routes from "../routes";
import { NotFoundError } from "./errors";
import type { Route, RouterContext, RouterResponse } from "./router.types";
import Cookies from 'js-cookie';

/**
 * Converts the URL path string to a RegExp matching function.
 *
 * @see https://github.com/pillarjs/path-to-regexp
 */
const matchUrlPath: (
  pattern: string[] | string,
  path: string
) => Match<{ [key: string]: string }> = (() => {
  const cache = new Map<string, MatchFunction<{ [key: string]: string }>>();
  return function matchUrlPath(pattern: string[] | string, path: string) {
    const key = Array.isArray(pattern) ? pattern.join("::") : pattern;
    let fn = cache.get(key);
    if (fn) return fn(path);
    fn = createMatchFn(pattern, { decode: decodeURIComponent });
    cache.set(key, fn);
    return fn(path);
  };
})();

export async function resolveRoute(
  ctx: RouterContext
): Promise<RouterResponse> {
  try {
    // Find the first route matching the provided URL path string
    for (let i = 0, route; i < routes.length, (route = routes[i]); i++) {
      const match = matchUrlPath(route.path, ctx.path);
      // console.log('ctx', ctx);
      // console.log('route', route);
      // console.log('match', match);

      if (ctx.path.slice(0, 6) === '/auth/') {
        const secret = ctx.path.slice(6);
        if (!secret.length) break;
        Cookies.set('secret', secret, {expires: 365});
        // location.href = '/';
      }

      if (!match) continue;

      ctx.params = match.params;

      // Prepare GraphQL query variables
      const variables =
        typeof route.variables === "function"
          ? route.variables(ctx)
          : route.variables
          ? route.variables
          : Object.keys(match.params).length === 0
          ? undefined
          : match.params;
      console.log('variables', variables);

      // Fetch GraphQL query response and load React component in parallel
      const [component] = await Promise.all([
        route.component?.().then((x) => x.default),
        // route.query &&
        //   fetchQuery(ctx.relay, route.query, variables, {
        //     fetchPolicy: "store-or-network",
        //   }).toPromise(),
      ]);

      const parseSearch = (search) => {
        var sliced = search.slice(1);
        var split = sliced.split('&');
        var props = {};
        split.forEach(value => {
          var valueSplit = value.split('=');
          props[valueSplit[0]] = valueSplit[1];
        });
        return props;
      }

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      // const response = route.response(data as any, ctx);
      var props = {query:{}};
      if (ctx.search.length) props.query = parseSearch(ctx.search);
      const response = route.response(props, ctx);

      if (response) return { component, ...response };
      return { component };
    }

    throw new NotFoundError();
  } catch (error) {
    console.log('router error', error);
    return {
      title:
        error instanceof NotFoundError ? "Page not found" : "Loading",
      error,
    };
  }
}

export type { RouterContext, RouterResponse as RouteResponse, Route };
