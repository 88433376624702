/* SPDX-FileCopyrightText: 2014-present Kriasoft <hello@kriasoft.com> */
/* SPDX-License-Identifier: MIT */

import type { Route } from "../../core";
import type Auth from "./Auth";

/**
 * Homepage route.
 */
export default {
  path: "/auth/:secret",
  component: () => import(/* webpackChunkName: "home" */ "./Auth"),
  response: (data) => ({
    title: "Authentication",
    description: "",
    props: data,
  }),
} as Route<typeof Auth>;
