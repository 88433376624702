/* SPDX-FileCopyrightText: 2014-present Kriasoft <hello@kriasoft.com> */
/* SPDX-License-Identifier: MIT */

import type { Route } from "../../core";
import type Appointment from "./Appointment";

/**
 * Homepage route.
 */
export default {
  path: "/appointment",
  component: () => import(/* webpackChunkName: "home" */ "./Appointment"),
  response: (data) => ({
    title: "Make Appointment",
    props: data,
    // description: "Web application built with React and Relay",
  }),
} as Route<typeof Appointment>;
