/* SPDX-FileCopyrightText: 2014-present Kriasoft <hello@kriasoft.com> */
/* SPDX-License-Identifier: MIT */

import account from "./account";
import home from "./home";
import legal from "./legal";
import appointment from "./appointment";
import roster from "./roster";
import bookings from "./bookings";
import admin from './admin';
import admin2 from './admin2';
import auth from './auth';
import services from './services';
import experience from './experience';
import team from './team';
import paid from './paid';
import generic from './generic';
import education from './education';
import gallery from './gallery';

/**
 * The list of application routes (pages).
 */
export default [home, account, appointment, auth, services,
  experience, team, paid, admin, admin2, education,
  gallery, ...legal, ...generic] as const;
