/* SPDX-FileCopyrightText: 2014-present Kriasoft <hello@kriasoft.com> */
/* SPDX-License-Identifier: MIT */

import type { Route } from "../../core";
import type Experience from "./Experience";

export default
  {
    path: "/experience",
    component: () => import(/* webpackChunkName: "legal" */ "./Experience"),
    response: () => ({
      title: "Experience",
    }),
  } as Route<typeof Experience>;
