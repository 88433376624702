/* SPDX-FileCopyrightText: 2014-present Kriasoft <hello@kriasoft.com> */
/* SPDX-License-Identifier: MIT */

import type { Route } from "../../core";
import type Services from "./Services";

/**
 * Homepage route.
 */
export default {
  path: "/services",
  component: () => import(/* webpackChunkName: "home" */ "./Services"),
  response: (data) => ({
    title: "Services",
    description: "",
    props: data,
  }),
} as Route<typeof Services>;
