/* SPDX-FileCopyrightText: 2014-present Kriasoft <hello@kriasoft.com> */
/* SPDX-License-Identifier: MIT */

import type { Route } from "../../core";
import type Admin from "./Admin";

/**
 * Homepage route.
 */
export default {
  path: "/admin",
  component: () => import(/* webpackChunkName: "home" */ "./Admin"),
  response: (data) => ({
    title: "Admin Controls",
    description: "",
    props: data,
  }),
} as Route<typeof Admin>;
