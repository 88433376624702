/* SPDX-FileCopyrightText: 2014-present Kriasoft <hello@kriasoft.com> */
/* SPDX-License-Identifier: MIT */

import type { Route } from "../../core";
import type Gallery from "./Gallery";

export default
  {
    path: "/gallery",
    component: () => import(/* webpackChunkName: "legal" */ "./Gallery"),
    response: () => ({
      title: "Gallery",
    }),
  } as Route<typeof Gallery>;
