/* SPDX-FileCopyrightText: 2014-present Kriasoft <hello@kriasoft.com> */
/* SPDX-License-Identifier: MIT */

import type { Route } from "../../core";
import type Education from "./Education";

export default
  {
    path: "/education",
    component: () => import(/* webpackChunkName: "legal" */ "./Education"),
    response: () => ({
      title: "Education Hub",
    }),
  } as Route<typeof Education>;
